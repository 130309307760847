import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['submitButton']

  submitButtonTarget!: HTMLElement

  submitOption() {
    this.submitButtonTarget.click()
  }
}
